import { useContext, useMemo } from 'react'
import { updateSettings } from '../services/api'
import { Context } from '../utils/context'
import { Game } from './Game'
import styles from './MainBet.module.css'
import Loader from 'react-spinners/BounceLoader'
import { Sidebar } from './Sidebar'
import { ScraperNotification } from './ScraperNotification'
import { GameIF } from '../utils/types'

export const MainBettor = () => {
    const { league, games, settings } = useContext(Context)
    const providerGames = useMemo(() => {
        return (
            games
                .filter((game) => game.league === league)
                .filter((game) => game.enabled)
                // sort by ascending order to get plive first
                .sort((a, b) => a.providerId - b.providerId)
                .reduce((acc: Record<string, GameIF[]>, game: GameIF) => {
                    const key = `${game.league}-${game.homeTeam}-${game.awayTeam}`
                    // some of the other providers have teams flipped
                    const altKey = `${game.league}-${game.awayTeam}-${game.homeTeam}`
                    // some of the other providers have full college names
                    const existingKey = Object.keys(acc).find((existingKey) => {
                        const [_, existingHomeTeam, existingAwayTeam] =
                            existingKey.split('-')
                        return (
                            game.homeTeam.includes(existingHomeTeam) &&
                            game.awayTeam.includes(existingAwayTeam)
                        )
                    })

                    // If a match is found, use the existing key to add the game
                    if (existingKey) {
                        acc[existingKey].push(game)
                    } else {
                        if (!acc[key]) {
                            if (acc[altKey]) {
                                acc[altKey].push(game)
                            }
                            acc[key] = []
                        }
                        acc[key].push(game)
                    }

                    return acc
                }, {})
        )
    }, [games, league])

    const activateServers = async () => {
        await updateSettings({
            bettorsActive: true,
            scrapersActive: true,
        })
    }
    return (
        <>
            <div className={styles.main}>
                {settings?.scrapersActive && <Sidebar />}
                <div className={styles.main_container}>
                    {!settings ? (
                        <div className={styles.loading}>
                            <Loader
                                loading={!settings}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        </div>
                    ) : !settings.scrapersActive ? (
                        <div className={styles.section}>
                            <div className={styles.section_header}>
                                Servers are currently off
                                <button onMouseDown={activateServers}>
                                    Turn on Servers
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {settings && !settings.bettorsActive && (
                                <div className={styles.section}>
                                    <div className={styles.section_header}>
                                        Bettors are currently off
                                        <button onMouseDown={activateServers}>
                                            Turn on Bettors
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div>
                                {Object.values(providerGames).map(
                                    (providerGame) => (
                                        <Game
                                            key={`${providerGame[0].league}-${providerGame[0].homeTeam}-${providerGame[0].awayTeam}`}
                                            games={providerGame}
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
                <ScraperNotification />
            </div>
        </>
    )
}
