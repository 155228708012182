import {
    ACCOUNT_STATUS,
    AccountIF,
    AccountScraperIF,
    BetIF,
    EnabledScraperSettingsIF,
    GameScraperIF,
    LoginPayloadIF,
    NotificationIF,
    OddsIF,
    ProviderIF,
    ReportFilterIF,
    ReportIF,
    ReportOverviewIF,
    SettingsIF,
} from '../utils/types'
import { getFiltersQuery } from '../utils/reports'

const TOKEN_KEY = 'login:token'
export const getStoredToken = (): LoginPayloadIF | undefined => {
    const token = sessionStorage.getItem(TOKEN_KEY)
    if (token === null) return undefined
    try {
        return JSON.parse(token)
    } catch {
        return undefined
    }
}
export const setStoredToken = (payload: LoginPayloadIF) => {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(payload))
}
export const deleteStoredToken = () => {
    sessionStorage.removeItem(TOKEN_KEY)
}

export const getUserToken = async (username: string, password: string) => {
    try {
        const savedToken = getStoredToken()
        if (savedToken && savedToken.token) {
            return savedToken
        }
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/login`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        const payload: LoginPayloadIF = (await res.json()).payload
        setStoredToken(payload)
        return payload
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getUserTokenWith2FA = async (userId: number, code: string) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/login/2fa`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    userId,
                    code,
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) throw new Error(await res.text())
        const payload: LoginPayloadIF = (await res.json()).payload
        setStoredToken(payload)
        return payload
    } catch (e: any) {
        console.error(e)
        throw new Error(e.message)
    }
}

export const resendTwoFactorAuthCode = async (userId: number) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/login/2fa/resend`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({ userId }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return true
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getOdds = async (gameIds: string[]) => {
    try {
        const res = await fetch(
            `${
                process.env.REACT_APP_SERVER_URL
            }/api/odds?gameIds=${gameIds.join(',')}`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as OddsIF[]
    } catch (e) {
        console.error(e)
        deleteStoredToken()
        return undefined
    }
}

export const getGames = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/games`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as GameScraperIF[]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getProviders = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/providers`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as {
            providers: ProviderIF[]
        }
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getSettings = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/settings`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as SettingsIF
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const updateSettings = async (body: {
    scrapersActive?: boolean
    bettorsActive?: boolean
}): Promise<SettingsIF> => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/settings`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify(body),
                credentials: 'include',
            }
        )
        if (res.status !== 200) throw new Error('Error saving settings')
        return (await res.json()).payload as SettingsIF
    } catch (e) {
        console.error(e)
        throw new Error('Error saving settings')
    }
}

export const getReport = async (
    startDate: Date,
    endDate: Date,
    selectedFilters: ReportFilterIF,
    page: number
) => {
    try {
        const filtersQuery = getFiltersQuery(selectedFilters)
        const res = await fetch(
            `${
                process.env.REACT_APP_SERVER_URL
            }/api/bets/report?page=${page}&start=${startDate.toISOString()}&end=${endDate.toISOString()}${filtersQuery}`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as ReportIF
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getReportSummary = async (
    startDate: Date,
    endDate: Date,
    type: 'Account' | 'Source',
    source?: string
) => {
    try {
        const res = await fetch(
            `${
                process.env.REACT_APP_SERVER_URL
            }/api/bets/report/summary?start=${startDate.toISOString()}&end=${endDate.toISOString()}&type=${type}${
                source ? `&source=${source}` : ''
            }`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as (ReportOverviewIF & {
            account?: string
            source?: string
        })[]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const downloadReport = async (
    startDate: Date,
    endDate: Date,
    includesBets: boolean,
    selectedFilters: ReportFilterIF
) => {
    try {
        let filtersQuery = ''
        if (includesBets) filtersQuery = getFiltersQuery(selectedFilters)
        const res = await fetch(
            `${
                process.env.REACT_APP_SERVER_URL
            }/api/bets/report/download?start=${startDate.toISOString()}&end=${endDate.toISOString()}&bets=${includesBets}${filtersQuery}`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return await res.blob()
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const checkToken = async () => {
    const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/check-token`,
        {
            method: 'GET',
            headers: {
                origin: process.env.REACT_APP_CLIENT_URL ?? '',
                'content-type': 'application/json;charset=UTF-8',
            },
            credentials: 'include',
        }
    )
    if (res.status !== 204) {
        throw new Error('Error checking token')
    }
}

export const getAccounts = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as AccountScraperIF[]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const createAccount = async (account: AccountIF) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    ...account,
                    accountProviders: account.accountProviders.map((ap) => ({
                        providerId: ap.providerId || ap.provider.id,
                    })),
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) throw new Error(await res.text())
        return (await res.json()).payload
    } catch (e: any) {
        console.error(e)
        throw new Error(e.message)
    }
}

export const updateAccount = async (account: AccountIF) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/${account.id}`,
            {
                method: 'PUT',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    ...account,
                    accountProviders: account.accountProviders.map((ap) => ({
                        providerId: ap.providerId || ap.provider.id,
                        accountId: ap.accountId || ap.account.id,
                    })),
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) throw new Error(await res.text())
        return (await res.json()).payload
    } catch (e: any) {
        console.error(e)
        throw new Error(e.message)
    }
}

export const deleteAccount = async (id: number) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/${id}`,
            {
                method: 'DELETE',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const toggleAccountStatus = async (
    id: number,
    status: ACCOUNT_STATUS
) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/${id}`,
            {
                method: 'PATCH',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({ status }),
                credentials: 'include',
            }
        )

        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const setGameEnabled = async (id: string, enabled: boolean) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/games/${id}`,
            {
                method: 'PATCH',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({ enabled }),
                credentials: 'include',
            }
        )
        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const restartScraper = async (id: string) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/games/scraper/${id}/restart`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({}),
                credentials: 'include',
            }
        )
        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const restartBettor = async (id: string) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/bettor/${id}/restart`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({}),
                credentials: 'include',
            }
        )
        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const reactivateAllAccounts = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/accounts/reactivate`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({}),
                credentials: 'include',
            }
        )
        return res
    } catch (error) {
        console.error(error)
        return undefined
    }
}

export const restartGameScraper = async (id: number) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/providers/${id}/restart`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({}),
                credentials: 'include',
            }
        )
        return res
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getBets = async (page: number) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/bets?page=${page}`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as BetIF[]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const placeBets = async (
    odds: OddsIF,
    accountIds: number[],
    amount: number | null
) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/bets/`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    odds,
                    accountIds,
                    amount: amount ?? undefined,
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getNotifications = async (page: number) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/notifications?page=${page}`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as NotificationIF[]
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getLastNotification = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/notifications?page=-1`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload[0] as NotificationIF
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const sendTimestampData = async (data: Record<string, number>) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/ops/timestamp`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({ data }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const getUserEnabledGameSettings = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/enabled-games-settings`,
            {
                method: 'GET',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as EnabledScraperSettingsIF
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export const setUserEnabledGameSettings = async (
    settings: EnabledScraperSettingsIF
) => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/enabled-games-settings`,
            {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_CLIENT_URL ?? '',
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify({
                    settings,
                }),
                credentials: 'include',
            }
        )
        if (res.status !== 200) return undefined
        return (await res.json()).payload as any
    } catch (e) {
        console.error(e)
        return undefined
    }
}
