import styles from './Sidebar.module.css'
import { useContext, useMemo } from 'react'
import { Sport } from './Sport'
import { Context } from '../utils/context'

export const Sidebar = () => {
    const {
        league: selectedLeague,
        setLeague: setSelectedLeague,
        games,
    } = useContext(Context)

    const leaguesDict = useMemo(
        () =>
            games
                .filter((game) => game.enabled)
                .map((game) => {
                    return { league: game.league, sport: game.sport }
                })
                .reduce(
                    (acc: { [sport: string]: string[] }, { sport, league }) => {
                        acc[sport] = acc[sport]
                            ? Array.from(new Set([...acc[sport], league]))
                            : [league]
                        return acc
                    },
                    {}
                ),
        [games]
    )

    return (
        <div className={styles.sidebar}>
            <header>Leagues</header>
            <div>
                {Object.keys(leaguesDict)
                    .sort((a, b) => a.localeCompare(b))
                    .map((sport, index) => (
                        <Sport
                            sport={sport}
                            leagues={leaguesDict[sport]}
                            key={index}
                            selectedLeague={selectedLeague}
                            setSelectedLeague={setSelectedLeague}
                        />
                    ))}
            </div>
        </div>
    )
}
