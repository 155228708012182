import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../utils/context'
import styles from './Settings.module.css'
import {
    AccountIF,
    CanonicalSportNamesIF,
    EnabledScraperSettingsIF,
    ProviderIF,
} from '../utils/types'
import Loader from 'react-spinners/BounceLoader'
import { Modal } from '../components/Modal'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { getAccountName } from '../utils/bets'
import {
    getProviders,
    getUserEnabledGameSettings,
    setGameEnabled,
    setUserEnabledGameSettings,
} from '../services/api'

const CanonicalSports: Array<CanonicalSportNamesIF> = [
    'Football',
    'Basketball',
    'Baseball',
    'Hockey',
    'Soccer',
    'Tennis',
    'Golf',
]

export const Games = () => {
    const { games } = useContext(Context)
    const [accountToEdit, setAccountToEdit] = useState<AccountIF | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [sort, setSort] = useState<{
        key: 'sport' | 'league' | 'homeTeam' | 'awayTeam' | 'provider'
        order: number
    }>({ key: 'sport', order: 1 })
    const [search, setSearch] = useState('')

    const accountsToShow = useMemo(() => {
        return games.filter((g) => {
            if (search === '') return true
            const regex = new RegExp(`${search}`, 'i')

            console.log(g.sport, g.league, g.homeTeam, g.awayTeam)
            return [
                g.sport,
                g.league,
                g.homeTeam,
                g.awayTeam,
                g.provider.name,
            ].some((x) => regex.test(x))
        })
    }, [games, search])

    const sortArrows = useCallback((order: number, show: boolean) => {
        return order > 0 ? (
            <BiDownArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        ) : (
            <BiUpArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        )
    }, [])

    const [providers, setProviders] = useState<ProviderIF[]>([])
    const [isLoadingSettings, setIsLoadingSettings] = useState(false)
    const [enabledSettings, setEnabledSettings] =
        useState<EnabledScraperSettingsIF>({
            providers: [],
            sports: [],
        })

    useEffect(() => {
        ;(async () => {
            const providers = await getProviders()
            if (providers) {
                setProviders(providers.providers)
            }
        })()
    }, [])

    useEffect(() => {
        setIsLoadingSettings(true)
        ;(async () => {
            try {
                const settings = await getUserEnabledGameSettings()
                if (settings) {
                    setEnabledSettings(settings)
                }
            } catch (e) {
                console.error(e)
            }
            setIsLoadingSettings(false)
        })()
    }, [])

    const updateSettings = async (settings: EnabledScraperSettingsIF) => {
        setIsLoadingSettings(true)
        try {
            await setUserEnabledGameSettings(settings)

            const latest = await getUserEnabledGameSettings()
            if (latest) {
                setEnabledSettings(latest)
            }
        } catch (e) {
            console.error(e)
        }

        setIsLoadingSettings(false)
    }

    return (
        <div className={styles.settings}>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>Providers</div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoadingSettings ? (
                            <Loader
                                loading={isLoadingSettings}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        ) : accountsToShow && accountsToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <td>
                                                <span>Enabled</span>
                                            </td>

                                            <td>
                                                <span>Provider</span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.scrollable}>
                                        {providers.map((provider) => (
                                            <tr key={provider.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={enabledSettings.providers.includes(
                                                            provider.id
                                                        )}
                                                        onChange={async () => {
                                                            await updateSettings(
                                                                {
                                                                    ...enabledSettings,
                                                                    providers:
                                                                        enabledSettings.providers.includes(
                                                                            provider.id
                                                                        )
                                                                            ? enabledSettings.providers.filter(
                                                                                  (
                                                                                      x
                                                                                  ) =>
                                                                                      x !==
                                                                                      provider.id
                                                                              )
                                                                            : [
                                                                                  ...enabledSettings.providers,
                                                                                  provider.id,
                                                                              ],
                                                                }
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td>{provider.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No games found.</p>
                        )}
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>Sports</div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoadingSettings ? (
                            <Loader
                                loading={isLoadingSettings}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        ) : accountsToShow && accountsToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <td>
                                                <span>Enabled</span>
                                            </td>

                                            <td>
                                                <span>Sport</span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.scrollable}>
                                        {CanonicalSports.map((sport) => (
                                            <tr key={sport}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={enabledSettings.sports.includes(
                                                            sport
                                                        )}
                                                        onChange={async () => {
                                                            await updateSettings(
                                                                {
                                                                    ...enabledSettings,
                                                                    sports: enabledSettings.sports.includes(
                                                                        sport
                                                                    )
                                                                        ? enabledSettings.sports.filter(
                                                                              (
                                                                                  x
                                                                              ) =>
                                                                                  x !==
                                                                                  sport
                                                                          )
                                                                        : [
                                                                              ...enabledSettings.sports,
                                                                              sport,
                                                                          ],
                                                                }
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td>{sport}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No games found.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.header}>
                <header>
                    Games {accountsToShow && `(${accountsToShow.length})`}
                </header>
                {accountToEdit === null && (
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search..."
                    />
                )}
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    <div className={styles.title_container}>
                        Game Management
                    </div>
                </div>
                <div className={styles.section_content}>
                    {isLoading ? (
                        <Loader
                            loading={isLoading}
                            size={48}
                            color={'var(--dark1)'}
                        />
                    ) : accountsToShow && accountsToShow.length > 0 ? (
                        <div className={styles.table_container}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <td>
                                            <span>Enabled</span>
                                        </td>

                                        <td>
                                            <span>Sport</span>
                                        </td>

                                        <td>
                                            <span>League</span>
                                            {sortArrows(
                                                sort.order,
                                                sort.key === 'league'
                                            )}
                                        </td>

                                        <td>
                                            <span>Home Team</span>
                                            {sortArrows(
                                                sort.order,
                                                sort.key === 'homeTeam'
                                            )}
                                        </td>

                                        <td>
                                            <span>Away Team</span>
                                            {sortArrows(
                                                sort.order,
                                                sort.key === 'awayTeam'
                                            )}
                                        </td>
                                        <td>
                                            <span>Provider</span>
                                            {sortArrows(
                                                sort.order,
                                                sort.key === 'provider'
                                            )}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className={styles.scrollable}>
                                    {accountsToShow
                                        .sort((a, b) => {
                                            switch (sort.key) {
                                                case 'sport':
                                                    return (
                                                        getAccountName(
                                                            a.sport
                                                        ).localeCompare(
                                                            getAccountName(
                                                                b.sport
                                                            )
                                                        ) * sort.order
                                                    )
                                                case 'league':
                                                    return (
                                                        (
                                                            a.league || ''
                                                        ).localeCompare(
                                                            b.league || ''
                                                        ) * sort.order
                                                    )
                                                default:
                                                    return 0
                                            }
                                        })
                                        .map((g) => (
                                            <tr key={g.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={g.enabled}
                                                        onChange={async () => {
                                                            // will be some delay before the checkbox updates (after update from webhook)
                                                            await setGameEnabled(
                                                                g.id,
                                                                !g.enabled
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td>{g.sport}</td>
                                                <td>{g.league}</td>
                                                <td>{g.homeTeam}</td>
                                                <td>{g.awayTeam}</td>
                                                <td>{g.provider.name}</td>
                                                <td className={styles.sticky}>
                                                    <div
                                                        className={styles.icons}
                                                    ></div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No games found.</p>
                    )}
                </div>
            </div>
        </div>
    )
}

const AccountModal = (props: {
    account: AccountIF
    onClose: () => void
    onConfirm: () => void
    action: string
}) => {
    const { account, onClose, onConfirm, action } = props
    return (
        <Modal onClose={onClose}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className={styles.modal}>
                    <p>{`Are you sure you want to ${action.toLowerCase()} account ${
                        account.username
                    } on ${account.website}?`}</p>
                    <div className={styles.modal_button_row}>
                        <button onClick={onClose} type="button">
                            Cancel
                        </button>
                        <button
                            className={styles.danger}
                            onClick={onConfirm}
                            type="submit"
                        >
                            {action}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
